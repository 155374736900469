import React from 'react';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import PropTypes, { shape } from 'prop-types';
import { ucFirst } from '../../utils/caseconverters';

import Image from '../Image';
import Button from '../Button';
import styles from './CardCta.module.scss';

const CardCta = ({
    title = '',
    text = '',
    theme = '',
    link = {},
    image = {},
}) => {
    const handleClick = (title) => {
        window._mtm = window._mtm || [];
        window._mtm.push({
            ctaButtonName: title,
            event: 'interactionsCallToActionButton',
        });
    };

    return (
        <div
            className={classNames(
                styles.CardCta,
                styles[`CardCta--${ucFirst(theme)}`]
            )}>
            <div className={styles.CardCta__Container}>
                <div className={styles['CardCta__ImageContainer']}>
                    <LazyLoad
                        once
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                        }}>
                        <Image
                            {...{ image }}
                            sizes={'(min-width: 1680px) 828px'}
                        />
                    </LazyLoad>
                </div>
                <div className={styles.CardCta__Content}>
                    <h2 className={styles.CardCta__Title}>{title}</h2>
                    <div
                        className={styles.CardCta__Text}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                    {link && link.href && (
                        <div className={styles.CardCta__Button}>
                            <Button
                                link={link}
                                onClick={() => handleClick(link.title)}
                                modifiers={
                                    theme === '' || 'blue' ? ['inverted'] : []
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

CardCta.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
        focal: shape({
            x: PropTypes.string,
            y: PropTypes.string,
        }),
        altText: PropTypes.string,
    }),
    theme: PropTypes.string,
};

export default CardCta;
